import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import Contact from '@components/contact';
import Hero from '../components/hero';

import Layout from '@components/layout';

import MarkdownContent from '../components/markdown-content/MarkdownContent';

const Manadsskydd = ({ data }) => {
  const {
    contentfulPage: {
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
    },
  } = data;

  const headLine = data.contentfulPage.headLine;
  const contactHeader = data.contentfulPage.pageContactHeader;
  const bodyText = data.contentfulPage.pageContent[0].bodyText;

  return (
    <Layout newStyle>
      <SEO title={headLine} />
      <Hero
        breadcrumbs={[{ path: '/', name: 'Hem' }]}
        title={pageHeader}
        subtitleMarkdown={pageHeaderDescription}
      />
      <MarkdownContent markdown={bodyText.bodyText} />
      <Contact headerText={contactHeader} isLargeContact={true} />
    </Layout>
  );
};

export default Manadsskydd;

Manadsskydd.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query ManadsskyddQuery {
    contentfulPage(pageUrl: { eq: "/manadsskydd" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
        childMarkdownRemark {
          html
        }
      }
      pageContactHeader
      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            bodyText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
